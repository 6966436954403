<template>
  <div class="menu">
    <ul>
      <li
        :class="{ active: activeMenu == item.id }"
        :key="item.id"
        v-for="item in menuList"
        data-testId="news-menu-item"
      >
        <router-link :to="`news?category=${item.id}`" data-testId="news-menu-link">{{ item.title }}</router-link>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    menuList: Array,
    activeMenu: String,
  },
};
</script>
<style scoped>
ul,
li {
  margin: 0;
  padding: 0;
}
.menu {
  background: #fff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
  min-height: 50px;
}
.menu ul {
  max-width: 1270px;
  min-height: 50px;
  margin: 0 auto;
  display: block;
}
.menu ul li {
  float: left;
  list-style: none;
}
.menu ul li a {
  color: #7e93a7;
  display: block;
  padding: 13px 15px;
}
.menu ul li a:hover {
  text-decoration: none;
}
.menu ul li.active a {
  color: #cb333b;
  box-shadow: 0 -3px #cb333b inset;
}
@media only screen and (max-width: 1000px) {
  .menu ul li {
    float: none;
  }
}
</style>
